<messages>["@/app/i18n/common/GenericObject", "../Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="contactData"
    :page.sync="pagination.page"
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    :footer-props="{...footerProps, 'items-per-page-options': rowsPerPageItems}"
    :server-items-length="totalCount"
    :loading="loading">
    <template #item="props">
      <tr>
        <td>
          <router-link
            :to="detailsLink (props.item)"
            :class="{deletedItemLink: props.item.deleted}"
            v-text="props.item.refID"/>
        </td>
        <td>
          <registry-type-link :value="props.item.registryType" cut-tlds/>
        </td>
        <td>
          <client-link :id="props.item.clientId"/>
        </td>
        <td>{{ props.item.emailAddress }}</td>
        <td>
          <span>{{ formatDate (props.item.creationDate) }}</span>
        </td>
        <td>
          <!--suppress JSCheckFunctionSignatures -->
          <action-buttons
            :value="isActionButtonsActive (props.item.versionId)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.versionId) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty')"/>
    </template>
  </v-data-table>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import registryCommands from '@/app/core/mixins/RegistryCommands'

  import {mapGetters, mapActions} from 'vuex'

  import ActionButtons from '@/app/core/components/ActionButtons'
  import ClientLink from '@/app/core/components/ClientLink'
  import RegistryTypeLink from '@/app/core/components/RegistryTypeLink'
  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'

  import {byRegistryContact as getDomainSearchFilterByContact}
    from '@/app/pages/Domain/components/DomainFilter'

  import {isDeleteProhibited, isUpdateProhibited}
    from '@/app/core/components/RegistryObject/StatesDialog'

  export default {
    name: 'ContactTable',

    components: {
      ActionButtons,
      ClientLink,
      RegistryTypeLink,
      SearchTableNoData
    },

    mixins: [paginationMixins, actionButtonsHelper, registryCommands],

    props: {
      totalCount: {type: Number, default: 0},
      contactData: {type: Array, default: () => []},
      rowsPerPageItems: {
        type: Array,
        default: undefined
      },
      loading: Boolean
    },

    data () {
      return {
        emailStatuses: {}
      }
    },

    computed: {
      ...mapGetters ({
        mayManageObject: 'auth/mayManageObject',
        permissions: 'auth/permissions',
        hasSubClients: 'auth/hasSubClients',
        mayManageForeignObjects: 'auth/mayManageForeignObjects'
      }),

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      },

      headers () {
        return [
          {
            text: this.$t ('label.handle'),
            value: 'handle'
          },
          {
            text: this.$t ('label.registryType'),
            sortable: false
          },
          {
            text: this.$t ('label.clientId'),
            value: 'clientId'
          },
          {
            text: this.$t ('label.email'),
            value: 'emailAddress'
          },
          {
            text: this.$t ('label.creationDate'),
            value: 'creationDate'
          },
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      },

      contactEmails () {
        let emails = []

        if (Array.isArray (this.contactData) && this.contactData.length) {
          emails = this.contactData.filter (
            it => !!it.emailAddress).map (it => it.emailAddress)
        }

        return emails
      }
    },

    watch: {
      contactEmails () {
        this.getEmailStatuses ()
      }
    },

    created () {
      this.getEmailStatuses ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        const view = [{
          action: 'view',
          registry: item.registryType,
          handle: item.refID,
          contactVersionId: item.versionId,
          contactDeleted: item.deleted,
          icon: 'visibility',
          tooltip: this.$t ('general.button.view')
        }]

        const edit = [{
          action: 'edit',
          registry: item.registryType,
          handle: item.refID,
          contactVersionId: item.versionId,
          contactDeleted: item.deleted,
          disabled: !this.supports (item.registryType, 'ContactModify') ||
            item.deleted || !(this.mayManageObject (item.clientId)) ||
            isUpdateProhibited (item.states),
          icon: 'edit',
          tooltip: this.$t ('general.button.edit')
        }]

        const states = [{
          ...item,
          action: 'states',
          disabled:
            !this.supports (item.registryType, 'ContactStatusModify') ||
            item.deleted || !(this.mayManageObject (item.clientId)),
          icon: 'settings',
          tooltip: this.$t ('general.button.manageStates')
        }]

        const clone = [{
          action: 'clone',
          registry: item.registryType,
          handle: item.refID,
          contactVersionId: item.versionId,
          contactDeleted: item.deleted,
          disabled: !this.supports (item.registryType, 'ContactCreate'),
          icon: 'content_copy',
          tooltip: this.$t ('general.button.clone')
        }]

        const sync = [...this.isAdmin
          ? [{
            action: 'sync',
            contactVersionId: item.versionId,
            disabled: !this.supports (item.registryType, 'ContactInquire'),
            icon: 'sync',
            tooltip: this.$t ('general.button.sync')
          }]
          : []]

        const emailValidate = [{
          action: 'emailValidate',
          registry: item.registryType,
          handle: item.refID,
          contactVersionId: item.versionId,
          contactDeleted: item.deleted,
          emailAddress: item.emailAddress,
          disabled: ['Valid', 'Pending'].includes (
            this.emailStatuses[item.emailAddress]),
          icon: 'email',
          tooltip: 'Reinitiate e-mail validation'
        }]

        const deleteAction = [{
          action: 'delete',
          actionArg: [item],
          icon: 'delete',
          tooltip: item.deletable
            ? this.$t ('contact.delete.inUse')
            : this.$t ('general.button.delete'),
          color: 'error',
          contactVersionId: item.versionId,
          disabled: !this.supports (item.registryType, 'ContactDelete') ||
            item.deletable || item.deleted ||
            isDeleteProhibited (item.states)
        }]

        const associated = [{
          action: 'associated',
          registry: item.registryType,
          handle: item.refID,
          icon: 'link',
          tooltip: this.$t ('general.button.associated'),
          disabled: item.deleted
        }]

        const shift = [...this.mayManageForeignObjects && this.hasSubClients
          ? [{
            action: 'shift',
            actionArg: [item],
            icon: 'swap_vert',
            tooltip: this.$t ('general.button.shift'),
            handle: item.refID,
            contactVersionId: item.versionId,
            contactId: item.id,
            disabled: item.deleted || item.pendingDeletion ||
              !(this.mayManageObject (item.clientId))
          }]
          : []
        ]

        return [
          ...view,
          ...states,
          ...edit,
          ...clone,
          {divider: true},
          ...deleteAction,
          {divider: true},
          ...associated,
          ...emailValidate,
          ...shift,
          ...sync // admin only
        ]
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        const params = (({registry, handle}) => ({registry, handle})) (button)

        switch (button.action) {
          case 'view':
            this.$router.push (
              button.contactDeleted
                ? {
                  name: 'contact.view.version',
                  params: {vid: button.contactVersionId}
                }
                : {
                  name: 'contact.view',
                  params
                })
            break
          case 'shift':
            this.$emit (
              'shift',
              {
                id: button.contactId,
                name: button.handle
              }
            )
            break
          case 'edit':
            this.$router.push ({
              name: 'contact.edit',
              params
            })
            break
          case 'states':
            this.$emit (
              'states',
              {
                v_id: button.versionId,
                id: button.id,
                name: button.refID
              }
            )
            break
          case 'clone':
            this.$router.push ({
              name: 'contact.clone',
              params
            })
            break
          case 'sync':
            this.$emit ('sync', [button.contactVersionId])
            break
          case 'emailValidate':
            this.$emit ('emailValidate', button.emailAddress)
            break
          case 'delete':
            this.$emit ('delete', [button.contactVersionId])
            break
          case 'associated':
            this.$router.push ({
              name: 'domain.search',
              query: {
                filter: JSON.stringify (
                  getDomainSearchFilterByContact (
                    button.registry, button.handle))
              }
            })
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      },

      /**
       * Generate the link data for the contact details view.
       *
       * @param {Object} contactData     the contact to create the link for
       */
      detailsLink (contactData) {
        return contactData.deleted
          ? {
            name: 'contact.view.version', params: {vid: contactData.versionId}
          }
          : {
            name: 'contact.view',
            params: {
              registry: contactData.registryType,
              handle: contactData.refID
            }
          }
      },

      getEmailStatuses () {
        const names = [...new Set (this.contactEmails)]

        if (names.length) {
          this.fetchData ({
            op: 'emailstatus/list',
            params: {
              names
            },
            cb: data => {
              this.emailStatuses = data.emails
            }
          })
        }
      },

      /**
       * handle pagination state changes
       *
       * @param newValue      new pagination state
       * @param oldValue      old pagination state
       */
      onPaginationStateChanged (newValue, oldValue) {
        this.$emit ('paginationStateChanged', {newValue, oldValue})
      }
    }
  }
</script>>
