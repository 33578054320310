<messages>["@/app/i18n/common/GenericObject", "./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '2' : '3'">
    <!-- filters -->
    <v-col cols="12" lg="8" xl="4">
      <contact-filter
        :value="filter || undefined"
        :visible="showFilter"
        :search-disabled="isLoading"
        @input="onFilterChange"
        @visibilityChanged="onFilterVisibilityChange"/>
    </v-col>

    <!-- contact list -->
    <v-col cols="12" xl="8">
      <v-alert
        v-model="showResultAlert"
        v-t="'general.info.searchResults'"
        type="info"
        color="primary"/>

      <v-card v-if="isResultTableVisible">
        <v-card-title primary-title>
          <div
            v-t="'list.title'"
            class="text-h5"/>
          <v-spacer/>
          <csv-download-btn :total-count="totalCount" :csv-download-url="csvDownloadUrl"/>
        </v-card-title>
        <v-card-text>
          <contact-table
            v-bind="paginationState"
            :contact-data="resultList"
            :loading="isLoading"
            :rows-per-page-items="rowsPerPageItems"
            :total-count="totalCount"
            @paginationStateChanged="({newValue, oldValue}) =>
              onPaginationStateChanged (newValue, oldValue)"
            @delete="deleteContacts"
            @emailValidate="verifyEmail"
            @states="openStatesDialog"
            @sync="syncContacts"
            @shift="openShiftDialog"/>
        </v-card-text>
        <registry-object-delete-dialog
          v-if="deleteObject"
          v-model="isDeleteDialogVisible"
          :delete-object="deleteObject"
          @deleted="load"/>
        <states-dialog
          v-if="contactObj"
          v-model="statesDialog"
          type="contact"
          :object="contactObj"
          @success="load"/>
        <simple-shift-dialog
          v-if="contactObj"
          v-model="shiftDialog"
          type="contact"
          :shift-object="contactObj"
          @shifted="load"/>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapState, mapMutations, mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import RegistryObjectDeleteDialog
    from '@/app/core/components/RegistryObject/RegistryObjectDeleteDialog'
  import ContactFilter from './components/ContactFilter'
  import ContactTable from './components/ContactTable'

  import SearchPage from '@/app/core/mixins/SearchPage'

  import StatesDialog
    from '@/app/core/components/RegistryObject/StatesDialog'

  import SimpleShiftDialog
    from '@/app/core/components/RegistryObject/SimpleShiftDialog'

  import CsvDownloadBtn from '@/app/core/components/CsvDownloadBtn'

  export default {
    name: 'ContactSearch',

    components: {
      CsvDownloadBtn,
      BaseLayout,
      ContactFilter,
      ContactTable,
      RegistryObjectDeleteDialog,
      StatesDialog,
      SimpleShiftDialog
    },

    mixins: [SearchPage],

    // define standard sorting properties (override property definitions from
    // `SearchPage` mixin)
    props: {
      sortBy: {
        type: String,
        default: 'handle'
      }
    },

    data () {
      return {
        // this value is used by mixin for sending requests to the BE API
        OPERATION: 'contact/list',
        DELETE_OBJECT_STATIC_PROPS: {
          url: 'contact/delete',
          objectType: 'contact'
        },
        contactObj: null,
        statesDialog: false,
        shiftDialog: false
      }
    },

    computed: {
      ...mapState ('filter', [
        'contactSearchQuery'
      ])
    },

    methods: {
      // --- mutations and actions, mapped from vuex ---------------------------
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        storeSearchQuery: 'filter/setContactSearchQuery'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * implementation of getting the stored search filter, which will be used
       * on component creation
       */
      getStoredSearchQuery () {
        return this.contactSearchQuery
      },

      // --- backend access ----------------------------------------------------

      /**
       * Synchronize contacts with the registry
       *
       * @param {Array} ids   contact version IDs to be synchronized
       */
      syncContacts (ids) {
        this.fetchData ({
          op: 'contact/sync',
          params: {
            ids
          },
          cb: data => {
            const count = Object.keys (data.ids).length
            this.displaySuccessMessage (
              this.$tc ('sync.success'),
              count, {
                count
              })

            this.load ()
          }
        })
      },

      /**
       * Start the verification of an e-mail address
       *
       * @param {Array} email   e-mail addresses to ve verified
       */
      verifyEmail (email) {
        this.fetchData ({
          op: 'emailstatus/verify',
          params: {
            email
          },
          cb: () => {
            this.displaySuccessMessage (
              this.$t ('verifyEmail.success', {email}))

            this.load ()
          }
        })
      },

      /**
       * Delete contacts, specified by their version IDs
       *
       * @param {Array} contactVersionIds   the array of contact version IDs
       *                                    to be deleted
       */
      deleteContacts (contactVersionIds) {
        // the BE currently doesn't support bulk deletion, thus
        // operation parameters and result must be manipulated to match the
        // JS API. After BE extension only this function should be corrected.
        const objToDelete =
          this.resultList.find (it => it.versionId === contactVersionIds[0])

        const normalized = {
          v_id: objToDelete.versionId,
          refId: objToDelete.refID,
          name: objToDelete.name
        }

        this.openConfirmDeleteDialog (normalized)
      },

      openStatesDialog (e) {
        this.contactObj = {
          ...e
        }
        this.statesDialog = true
      },

      openShiftDialog (e) {
        this.contactObj = {
          ...e
        }
        this.shiftDialog = true
      }
    }
  }
</script>
