<messages>["@/app/i18n/common/GenericObject", "../Contact"]</messages>

<template>
  <search-filter-wrapper
    v-model="filterVisibilityModel"
    type="contact"
    :quick-filter-items="quickFilterItems"
    :additional-quick-filter-items="additionalQuickFilterItems"
    :search-disabled="searchDisabled"
    @deleteFilter="onDeleteFilter"
    @deleteAdditionalFilter="onDeleteAdditionalFilter"
    @reset="resetSearchFilter"
    @search="doSearch"
    @focus="focus">
    <template #basic>
      <!-- contact name filters -->
      <v-row>
        <v-col v-bind="max1Attrs" class="pa-1">
          <v-text-field
            ref="handle"
            v-model.trim="filterParams.handle"
            name="handle"
            :label="$t (`label.handle`)"
            clearable/>
        </v-col>
        <template v-if="mayViewAllObjects || (mayViewSubEntitiesObjects && hasSubClients)">
          <v-col
            class="pa-1"
            cols="12" sm="12">
            <client-select
              ref="clientId"
              v-model="filterParams.clientId"
              show-inactive
              for-view
              nullable/>
          </v-col>
        </template>
        <v-col
          class="pa-1"
          cols="12"
          :class="{'col-sm-12': (mayViewAllObjects || (mayViewSubEntitiesObjects && hasSubClients))}">
          <registry-select
            ref="registryTypes"
            v-model="filterParams.registryTypes"
            include-inactive
            multiple
            clearable
            :label="$t('label.registryTypes')"
            :hint="$t ('label.registryTypesHint')"/>
        </v-col>
      </v-row>
    </template>
    <template #additional>
      <v-row>
        <v-col
          v-for="prop in ['name', 'organization', 'streets']"
          :key="prop"
          v-bind="max3Attrs">
          <v-text-field
            :ref="prop"
            v-model.trim="filterParams[prop]"
            :name="prop"
            :label="$t (`label.${prop}`)"
            clearable/>
        </v-col>
        <v-col
          v-for="prop in ['postalCode', 'city', 'stateOrProvince']"
          :key="prop"
          v-bind="max3Attrs">
          <v-text-field
            :ref="prop"
            v-model.trim="filterParams[prop]"
            :name="prop"
            :label="$t (`label.${prop}`)"
            clearable/>
        </v-col>
        <v-col cols="12" sm="6">
          <country-select
            ref="countryCode"
            v-model="filterParams.countryCode"
            :label="$t('label.countryCode')"/>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="email"
            v-model.trim="filterParams.email"
            name="email"
            :label="$t (`label.email`)"
            clearable/>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            ref="contactState"
            v-model="filterParams.contactState"
            :label="$t ('state.label')"
            :items="contactStates"/>
        </v-col>
      </v-row>
    </template>
  </search-filter-wrapper>
</template>

<script>
  import {mapGetters} from 'vuex'

  import ClientSelect from '@/app/core/components/ClientSelect'
  import CountrySelect from '@/app/core/components/CountrySelect'
  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import SearchFilterWrapper, {
    max1Attrs,
    max3Attrs
  } from '@/app/core/components/Search/SearchFilterWrapper'

  import SearchFilterMixinCreator from '@/app/core/mixins/SearchFilterCreator'
  import InputHelper from '@/app/core/mixins/InputHelper'
  import QuickFilterMixin from '@/app/core/mixins/QuickFilterMixin'

  /**
   * default contact search filter parameters
   * @type {Object}
   */
  export const defaultValue = () => ({
    handle: '',
    registryTypes: [],
    name: '',
    organization: '',
    streets: null,
    postalCode: '',
    city: '',
    stateOrProvince: '',
    countryCode: null,
    email: '',
    contactState: 'active',
    clientId: null
  })

  export default {
    name: 'ContactFilter',

    components: {
      CountrySelect,
      ClientSelect,
      RegistrySelect,
      SearchFilterWrapper
    },

    mixins: [
      SearchFilterMixinCreator (defaultValue),
      InputHelper,
      QuickFilterMixin
    ],

    data () {
      return {
        max1Attrs,
        max3Attrs,
        additionalFilterKeys: [
          'name',
          'organization',
          'streets',
          'postalCode',
          'city',
          'stateOrProvince',
          'countryCode',
          'email',
          'contactState'
        ],
        basicFilter: ['handle', 'clientId', 'registryTypes']
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'mayViewAllObjects',
        'mayViewSubEntitiesObjects',
        'hasSubClients'
      ]),

      contactStates () {
        return ['active', 'deleted', 'any'].map (s => ({
          text: this.$t (`state.${s}`),
          value: s
        }))
      },

      basicFilterLabels () {
        return this.basicFilter.map ((label) => {
          return this.$t (`label.${label}`)
        })
      }
    }
  }
</script>
